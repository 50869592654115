<template>
  <div class="navRight">
    <div class="navRight_header flex-sb">
      <div class="title flex">
        <p style="font-size:1.125rem" class="hover_opacity" @click="jumpTaskManage">{{ t('159') }}&nbsp;</p>
        <p style="font-size:1.125rem">/ {{ t('161') }}</p>
      </div>
      <div class="flex-sb" style="height: 100%">
        <div class="icon_btn blue_btn" @click="exportTaskRecord()" v-checkAuth="'task-task-export'">
          <el-icon class="element_icon"><Upload /> </el-icon>&nbsp;{{ t('647') }}
        </div>
        <div class="icon_btn red_btn" style="margin-top: 2px" @click="batchDel" v-checkAuth="'task-task-delete'">
          <el-icon class="element_icon"><Delete /> </el-icon>&nbsp;{{ t('210') }}
        </div>
      </div>
    </div>
    <div class="navRight_footer">
      <search-bar
        class="search"
        :searchPlaceholder="t('794')"
        width="19.625rem"
        v-model:searchContent="state.queryForm.keyword"
        @on-search="getData"
      />
      <ele-select
        :label="t('385')"
        :options="state.statusList"
        :labelname="'desc'"
        :value="'value'"
        v-model:selectVal="state.queryForm.taskStatus"
        v-model:selectLable="state.queryForm.taskValue"
        @on-select="getData()"
      />
      <ele-select
        :label="t('423')"
        :options="memberList"
        :labelname="'username'"
        :value="'id'"
        v-model:selectVal="memberList.id"
        @on-select="getData(true)"
      />
    </div>
  </div>
  <div class="container">
    <ele-table
      :tableColumnArray="tableColumnArray"
      :tableData="state.tableData"
      v-model:pageNo="state.queryForm.pageNo"
      v-model:pageSize="state.queryForm.pageSize"
      :total="state.queryForm.total"
      @handleNoChange="getData"
      @handleSizeChange="getData"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:buttonHanlder>
        <el-table-column :label="t('610')" align="center">
          <template v-slot="{ row }">
            <el-row>
              <el-col :span="12">
                <span class="blue_btn" @click.stop="jumpExecRecord(row.recordId)">{{ t('162') }}</span>
              </el-col>
              <el-col :span="12">
                <span class="blue_btn" @click.stop="picDetail(row.recordId)"
                  >{{ t('391') }}</span
                >
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </template>
    </ele-table>
  </div>
  <picturedetail
    v-if="state.isPicdetail"
    v-model:isVisible="state.isPicdetail"
    :picRecordId="state.picRecordId"
  ></picturedetail>
</template>

<script setup>
import { t } from '../../languages';
import { useRoute, useRouter } from "vue-router";
import { reactive, ref, watch } from "vue";
import taskManage from "@/network/task";
import { ElMessage, ElMessageBox } from "element-plus";
import download from "@/network/download";
import picturedetail from '../../components/page/data-manage/components/picture-detail.vue';
const route = useRoute();

//表头名称
const tableColumnArray = [
  { label: t("798"), prop: "recordId", width: "125%" },
  { label: t("423"), prop: "username", width: "100%" },
  { label: t("799"), prop: "execTime" },
  { label: t("800"), prop: "endTime" },
  { label: t("385"), prop: "taskStatus", width: "125%" },
  { label: t("234"), prop: "photoNumber", width: "100%" },
  { label: t("413"), prop: "remark" },
];

const state = reactive({
  tableData: [],
  queryForm: {
    keyword: "",
    pageNo: 1,
    pageSize: 10,
    total: 0,
    taskStatus: "",
    taskValue:''
  },
  statusList: [],
  picRecordId: "",
  isPicdetail: false,
});

// 获取下发人下拉菜单
const memberList = ref([]);
const getMemberList = async () => {
  const res = await taskManage.getMemberList({});
  memberList.value = res.resultData;
};
getMemberList();

watch(() =>route.query.taskId,(newValue,oldValue)=> {
        if (newValue) {
          getData();
        }
},{ immediate: true })

async function getData() {
  const tableData = await taskManage.getTaskRecord({
    taskId: route.query.taskId,
    keyword: state.queryForm.keyword,
    pageNo: state.queryForm.pageNo,
    pageSize: state.queryForm.pageSize,
    taskStatus: state.queryForm.taskStatus,
    sn: '',
    userId: memberList.value.id,
  });
  if (tableData.resultData.data instanceof Array) {
    state.tableData = tableData.resultData.data;
    state.queryForm.total = tableData.resultData.total;
  } else {
    state.tableData = [];
    state.queryForm.total = 0;
  }
}




/* 获取任务状态下拉菜单 */
async function getTaskStatusList() {
  const statusData = await taskManage.getTaskStatusList();
  if (statusData.resultData instanceof Array) {
    state.statusList = statusData.resultData;
  }
}
getTaskStatusList();

// 选中数据
const ids = ref([]);
const handleSelectionChange = (row) => {
  let res = row.map((item) => item.recordId);
  ids.value = res;
};

/* 批量删除 */
async function batchDel() {
  if (ids.value.length > 0) {
    ElMessageBox.confirm(
      t("801"),
      t("130"),
      {
        confirmButtonText: t("218"),
        cancelButtonText: t("217"),
      }
    )
      .then(() => {
        taskManage
          .deleteTaskRecord({
            ids: ids.value,
          })
          .then(() => {
            ElMessage({
              message: t("560"),
              type: "success",
            });
            if (
              state.queryForm.pageNo > 1 &&
              ids.value.length === state.queryForm.pageSize
            ) {
              state.queryForm.pageNo--;
            }
            getData();
          });
      })
      .catch(() => {
        ElMessage(t("549"));
      });
  } else {
    ElMessage(t("802"));
  }
}

//导出数据
const exportTaskRecord = async () => {
  await download.downloadSource(
    `/api/v1/task/record/exportExcel`,
    {
      keyword: state.queryForm.keyword,
      taskId: route.query.taskId,
      taskStatus: state.queryForm.taskStatus,
      userId: memberList.value.id,
      pageNo: state.queryForm.pageNo,
      pageSize: state.queryForm.pageSize,
    },
    "application/vnd.ms-excel",
    "get"
  );
};

//跳转回任务管理
const router = useRouter();
function jumpTaskManage() {
  router.push("/taskManagement");
}

//跳转到执行记录
function jumpExecRecord(recordId){
  router.push({
    path:"/executeRecord",
    query:{
      recordId:recordId,
      taskId: route.query.taskId
    }
  });
}

/* 打开图片详情 */
function picDetail(recordId) {
  console.log(recordId);
  state.picRecordId = recordId;
  state.isPicdetail = true;
}
</script>

<style lang='less' scoped>
.navRight {
  height: 6.5625rem;
  margin-left: 23.125rem;
  background: rgba(0, 28, 49, 0.8);
  padding: 1.0625rem 1.5625rem;

  .navRight_header {
    p {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #33ddf7;
    }

    .element_icon {
      font-size: 1.125rem;
    }
  }
  .navRight_footer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.9375rem;
    height: 40px;
  }
}
.container {
  border-top: 10px solid black;
  padding: 0 20px;
  height: calc(100% - 7.0625rem);
  background: rgba(0, 28, 49, 0.8);
}
</style>